<!-- * MODAL LIST TRUCKS -->
<mat-dialog-actions class="modal">
	<div class="header">
		<span></span>
		<button class="btn btn-close" mat-dialog-close="true">
			<mat-icon class="material-symbols-outlined" svgIcon="mat_outline:close"></mat-icon>
		</button>
	</div>

	<div class="modal-body">
		<div class="title">
			<h1 class="font-bold">{{ 'modal.ad.vehicle.truck.title' | translate }}</h1>
			<h4>{{ 'modal.ad.subTitle' | translate }}</h4>
		</div>

		<div class="content-cards">
			<a
				class="card"
				mat-dialog-close="true"
				appGaClick
				gaClickCategory="modal"
				gaClickAction="click"
				gaClickLabel="click-crear-camion-sobre-7.5"
				routerLink="/sale/create/truck/over-75"
			>
				<img src="/assets/images/ads/camion.svg" alt="truck-logo" />
				<!-- /seller/sell-truck-overweight -->

				<h2 class="font-bold">{{ 'globals.filter.typeVehicle.truckType.over7t' | translate }}</h2>
			</a>

			<a
				class="card"
				mat-dialog-close="true"
				appGaClick
				gaClickCategory="modal"
				gaClickAction="click"
				gaClickLabel="click-crear-camion-debajo-7.5"
				[routerLink]="'/sale/create/truck/under-75'"
			>
				<img src="/assets/images/ads/furgoneta.svg" alt="mobile-home-logo" />
				<!-- /seller/sell-truck-underweight -->

				<h2 class="font-bold">{{ 'globals.filter.typeVehicle.truckType.under7t' | translate }}</h2>
			</a>

			<a
				class="card"
				mat-dialog-close="true"
				appGaClick
				gaClickCategory="modal"
				gaClickAction="click"
				gaClickLabel="click-crear-camion-construccion"
				[routerLink]="'/sale/create/truck/construction'"
			>
				<img src="/assets/images/ads/construccion.svg" alt="construction-logo" />
				<!-- /seller/sell-truck-construction -->

				<h2 class="font-bold">
					{{ 'globals.filter.typeVehicle.truckType.construction' | translate }}
				</h2>
			</a>

			<a
				class="card"
				mat-dialog-close="true"
				appGaClick
				gaClickCategory="modal"
				gaClickAction="click"
				gaClickLabel="click-crear-camion-bus"
				[routerLink]="'/sale/create/truck/bus'"
			>
				<img src="/assets/images/ads/autobus.svg" alt="bus-logo" />
				<!-- /seller/sell-truck-bus -->

				<h2 class="font-bold">{{ 'globals.filter.typeVehicle.truckType.bus' | translate }}</h2>
			</a>

			<a
				class="card"
				mat-dialog-close="true"
				appGaClick
				gaClickCategory="modal"
				gaClickAction="click"
				gaClickLabel="click-crear-camion-agricola"
				[routerLink]="'/sale/create/truck/agriculture'"
			>
				<img src="/assets/images/ads/agricola.svg" alt="agriculture-logo" />
				<!-- /seller/sell-truck-farm -->

				<h2 class="font-bold">{{ 'globals.filter.typeVehicle.truckType.farm' | translate }}</h2>
			</a>

			<a
				class="card"
				mat-dialog-close="true"
				appGaClick
				gaClickCategory="modal"
				gaClickAction="click"
				gaClickLabel="click-crear-camion-elevadora"
				[routerLink]="'/sale/create/truck/forklift'"
			>
				<!-- /seller/sell-truck-forklift -->
				<img src="/assets/images/ads/elevadora.svg" alt="forklift-logo" />
				<h2 class="font-bold">{{ 'globals.filter.typeVehicle.truckType.forklift' | translate }}</h2>
			</a>

			<a
				class="card"
				mat-dialog-close="true"
				appGaClick
				gaClickCategory="modal"
				gaClickAction="click"
				gaClickLabel="click-crear-camion-semi-trailer-camion"
				[routerLink]="'/sale/create/truck/semi-trailer-truck'"
			>
				<!-- /seller/sell-truck-trailer type 2 -->
				<img src="/assets/images/ads/tractorsemiremolque.svg" alt="semi-trailer-truck-logo" />

				<h2 class="font-bold">
					{{ 'globals.filter.typeVehicle.truckType.semiTrailerTruck' | translate }}
				</h2>
			</a>

			<a
				class="card"
				mat-dialog-close="true"
				appGaClick
				gaClickCategory="modal"
				gaClickAction="click"
				gaClickLabel="click-crear-camion-trailer"
				[routerLink]="'/sale/create/truck/trailer'"
			>
				<!-- /seller/sell-truck-trailer type 1 -->
				<img src="/assets/images/ads/remolque.svg" alt="trailer-logo" />

				<h2 class="font-bold">{{ 'globals.filter.typeVehicle.truckType.trailer' | translate }}</h2>
			</a>

			<a
				class="card"
				mat-dialog-close="true"
				appGaClick
				gaClickCategory="modal"
				gaClickAction="click"
				gaClickLabel="click-crear-camion-semi-trailer"
				[routerLink]="'/sale/create/truck/semi-trailer'"
			>
				<!-- /seller/sell-truck-trailer type 3-->
				<img src="/assets/images/ads/semiremolque.svg" alt="semi-trailer-logo" />

				<h2 class="font-bold">
					{{ 'globals.filter.typeVehicle.truckType.semiTrailer' | translate }}
				</h2>
			</a>
		</div>
	</div>
</mat-dialog-actions>
