import { NgIf } from '@angular/common';
import { Component, Inject, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
	MatDialogModule
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SelectTruckModalComponent } from '../select-truck-modal/select-truck-modal.component';
import { GaClickDirective } from '@shared/directives/ga-click.directive';
import { ModalSelectVehicleComponent } from '@shared/components/modal-select-vehicle/modal-select-vehicle.component';

@Component({
	selector: 'app-modal-ads',
	templateUrl: './modal-ads.component.html',
	styleUrls: ['./modal-ads.component.scss'],
	standalone: true,
	imports: [RouterModule, TranslateModule, MatDialogModule, NgIf, GaClickDirective, MatIconModule]
})
export class ModalAdsComponent implements AfterViewInit {
	constructor(
		public dialogRef: MatDialogRef<ModalAdsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialog: MatDialog,
		private router: Router
	) {}
	modal: any = null;

	ngAfterViewInit(): void {
		if (this.data) {
			this.dialogRef.close();
			this.openModalSelectVehicle();
		}
	}

	openModalSelectVehicle() {
		this.dialog.open(ModalSelectVehicleComponent, {
			maxWidth: '1000px',
			maxHeight: 'max-content',
			height: 'max-content',
			width: '100%',
			panelClass: 'full-screen-modal'
		});
	}

	openModalSelectTrucks() {
		this.dialog.open(SelectTruckModalComponent, {
			maxWidth: '1000px',
			maxHeight: 'max-content',
			height: 'max-content',
			width: '100%',
			panelClass: 'full-screen-modal'
		});
	}

	goTo(route: string, params: any = {}) {
		this.router.navigate([route], { queryParams: params });
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
}
