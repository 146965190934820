import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { GaClickDirective } from '@shared/directives/ga-click.directive';

@Component({
	selector: 'app-select-truck-modal',
	templateUrl: './select-truck-modal.component.html',
	styleUrls: ['./select-truck-modal.component.scss'],
	standalone: true,
	imports: [TranslateModule, RouterModule, MatDialogModule, MatIconModule, GaClickDirective]
})
export class SelectTruckModalComponent {
	data: any = {};
}
