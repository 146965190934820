<mat-dialog-actions class="modal">
	<div class="header">
		<span></span>
		<button class="btn btn-close" mat-dialog-close="true">
			<mat-icon class="material-symbols-outlined" svgIcon="mat_outline:close"></mat-icon>
		</button>
	</div>

	<div class="modal-body">
		<div class="title">
			<h1 class="font-bold">{{ 'modal.ad.vehicle.title' | translate }}</h1>
			<h4>{{ 'modal.ad.subTitle' | translate }}</h4>
		</div>

		<div class="content-cards">
			<div
				class="card"
				mat-dialog-close="true"
				appGaClick
				gaClickCategory="modal"
				gaClickAction="click"
				gaClickLabel="click-crear-auto"
				(click)="data ? goTo('/seller/bulk-import', { type: 1 }) : goTo('/sale/create/auto')"
			>
				<!-- /seller/sell-vehicle  -->
				<img src="https://cdn.autosmotos.es/assets/auto.webp" alt="car-logo" />
				<h2 class="font-bold">{{ 'globals.filter.typeVehicle.auto' | translate }}</h2>
			</div>

			<div
				class="card"
				mat-dialog-close="true"
				appGaClick
				gaClickCategory="modal"
				gaClickAction="click"
				gaClickLabel="click-crear-moto"
				(click)="data ? goTo('/seller/bulk-import', { type: 2 }) : goTo('/sale/create/bike')"
			>
				<!-- /seller/sell-bike -->
				<img src="https://cdn.autosmotos.es/assets/moto.webp" alt="moto-logo" />
				<h2 class="font-bold">{{ 'globals.filter.typeVehicle.moto' | translate }}</h2>
			</div>

			<div
				class="card"
				mat-dialog-close="true"
				appGaClick
				gaClickCategory="modal"
				gaClickAction="click"
				gaClickLabel="click-crear-camion"
				(click)="openModalSelectTrucks()"
			>
				<img src="https://cdn.autosmotos.es/assets/camion.webp" alt="truck-logo" />
				<h2 class="font-bold">{{ 'globals.filter.typeVehicle.truck' | translate }}</h2>
			</div>

			<div
				class="card"
				mat-dialog-close="true"
				appGaClick
				gaClickCategory="modal"
				gaClickAction="click"
				gaClickLabel="click-crear-caravana"
				(click)="data ? goTo('/seller/bulk-import', { type: 3 }) : goTo('/sale/create/mobile-home')"
			>
				<!-- /seller/sell-mobile-home  -->
				<img src="/assets/images/ads/caravana.svg" alt="mobile-home-logo" />
				<h2 class="font-bold">{{ 'globals.filter.typeVehicle.mobileHome' | translate }}</h2>
			</div>
		</div>
	</div>
</mat-dialog-actions>
