import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SelectTruckModalComponent } from '../select-truck-modal/select-truck-modal.component';

@Component({
	selector: 'app-modal-select-vehicle',
	templateUrl: './modal-select-vehicle.component.html',
	styleUrls: ['./modal-select-vehicle.component.scss'],
	standalone: true,
	imports: [MatDialogModule, TranslateModule, MatIconModule]
})
export class ModalSelectVehicleComponent {
	constructor(
		private readonly router: Router,
		private readonly dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	goTo(route: string, params: any = {}): void {
		this.router.navigate([route], { queryParams: params });
	}

	openModalSelectTrucks(): void {
		this.dialog.open(SelectTruckModalComponent, {
			maxWidth: '1000px',
			maxHeight: 'max-content',
			height: 'max-content',
			width: '100%',
			panelClass: 'full-screen-modal'
		});
	}
}
