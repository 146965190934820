<mat-dialog-actions *ngIf="!data" class="modal">
	<div class="header">
		<span></span>
		<button class="btn btn-close" mat-dialog-close="true">
			<mat-icon class="material-symbols-outlined" svgIcon="mat_outline:close"></mat-icon>
		</button>
	</div>

	<div class="modal-body">
		<div class="title">
			<h1 class="font-bold">{{ 'modal.ad.title' | translate }}</h1>
			<h4>{{ 'modal.ad.subTitle' | translate }}</h4>
		</div>

		<div class="container-cards">
			<div class="content-cards">
				<div
					class="card"
					mat-dialog-close="true"
					appGaClick
					gaClickCategory="modal"
					gaClickAction="click"
					gaClickLabel="click-crear-auto"
					(click)="openModalSelectVehicle()"
				>
					<img src="https://cdn.autosmotos.es/assets/vehicles.webp" alt="car-logo" />
					<h2 class="font-bold">{{ 'modal.ad.sell.title' | translate }}</h2>
					<h3>{{ 'modal.ad.sell.subTitle' | translate }}</h3>
				</div>

				<div
					class="card"
					mat-dialog-close="true"
					appGaClick
					gaClickCategory="modal"
					gaClickAction="click"
					gaClickLabel="click-crear-alquiler"
					routerLink="/sale/create/rental"
				>
					<!-- /seller/sell-rental -->
					<img src="https://cdn.autosmotos.es/assets/rent.webp" alt="rent-logo" />
					<h2 class="font-bold">{{ 'modal.ad.rental.title' | translate }}</h2>
					<h3>{{ 'modal.ad.rental.subTitle' | translate }}</h3>
				</div>

				<div
					class="card"
					mat-dialog-close="true"
					appGaClick
					gaClickCategory="modal"
					gaClickAction="click"
					gaClickLabel="click-crear-taller"
					routerLink="/sale/create/mechanic"
				>
					<!-- /seller/sell-mechanic -->
					<img src="https://cdn.autosmotos.es/assets/mechanic.webp" alt="mechanic-logo" />
					<h2 class="font-bold">{{ 'modal.ad.garage.title' | translate }}</h2>
					<h3>{{ 'modal.ad.garage.subTitle' | translate }}</h3>
				</div>

				<div
					class="card"
					mat-dialog-close="true"
					appGaClick
					gaClickCategory="modal"
					gaClickAction="click"
					gaClickLabel="click-crear-reemplazo"
					routerLink="/sale/create/replacement"
				>
					<!-- /seller/sell-replacement -->
					<img src="https://cdn.autosmotos.es/assets/replacement.webp" alt="replacement-logo" />
					<h2 class="font-bold">{{ 'modal.ad.replacement.title' | translate }}</h2>
					<h3>{{ 'modal.ad.replacement.subTitle' | translate }}</h3>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-actions>
